
import { mapMutations } from 'vuex'
export default {
  async asyncData ({ params, $axios }) {
    const res = await $axios.get('sample_product/' + params.id)
    return {
      sampleProduct: res.data
    }
  },
  data () {
    return {
      sampleProduct: null
    }
  },
  methods: {
    ...mapMutations(['showLoading']),
    linkToProduct (pkgType, mappingInputId, productId) {
      if (pkgType !== 4) {
        return { name: 'customize-product-design-id', params: { design: true, id: mappingInputId }, query: { productId } }
      } else {
        return { name: 'products-detail-id', params: { id: mappingInputId }, query: { productId } }
      }
    },
    openLinkNewTab (link) {
      window.open(link, '_blank')
    },
    getYoutubeThumbnail (link) {
      const splitLink = link.split('/')
      return 'https://img.youtube.com/vi/' + splitLink[4] + '/hqdefault.jpg'
    }
  },
  head () {
    if (this.sampleProduct) {
      return {
        title: this.sampleProduct.name,
        meta: [
          { hid: 'description', name: 'description', content: this.sampleProduct.description },
          { hid: 'keywords', name: 'keywords', content: this.sampleProduct.name },
          { hid: 'og:title', property: 'og:title', content: this.sampleProduct.name },
          { hid: 'og:description', property: 'og:description', content: this.sampleProduct.description },
          { hid: 'og:image', property: 'og:image', content: this.$imgProxy.getUrl(this.sampleProduct.img[0].path, 850, 0) }
        ]
      }
    }
  }
}
